import { defineStore } from 'pinia'
import ControlEnvioService from '../services/controlEnvio'
const _ControlEnvioService = new ControlEnvioService()
export const useListar = defineStore('listar', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      count: 0,
      rows: [],
      limit: 10,
      offset: 0,
      pageOptions: [2, 10, 20, 30, 50, 100],
      filter: {
        fechaInicio: null,
        fechaFin: null,
        numeroEnvio: null,
        numeroGuia: null
      }
    }
  },
  getters: {
    _count: (state) => state.count,
    _rows: (state) => state.rows,
    _limit: (state) => state.limit,
    _offset: (state) => state.offset,
    _pageOptions: (state) => state.pageOptions
  },
  actions: {
    listar () {
      const params = {
        limit: this.limit,
        offset: this.offset,
        ...this.filter
      }
      _ControlEnvioService.listar(params).then(({ data }) => {
        this.rows = data.rows
        this.count = data.count
      })
    }
  }
})
