<template>
  <div class="flex flex-col">
    <div class="my-3">
      <MenuTab/>
    </div>
    <div class="flex justify-center filters">
      <Filters />
    </div>
    <div class="w-full datatable">
      <Table :rows="_useListar._rows"/>
    </div>
    <div class="paginator">
      <Paginator/>
    </div>
  </div>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'
import { useListar } from '../../../store/listar.store'
const _useListar = useListar()
const Paginator = defineAsyncComponent(() => import('../../components/listar/paginate.vue'))
const Table = defineAsyncComponent(() => import('../../components/listar/table.vue'))
const Filters = defineAsyncComponent(() => import('../../components/listar/filters.vue'))
const MenuTab = defineAsyncComponent(() => import('../../components/global/tabMenu.vue'))
</script>

<style scoped>

</style>
